@use '../../../../app/styles/fonts';

.PaymentScreenshot {
  margin: 24px 0;
}

.CabinetButton {
  margin-top: 32px;
  align-self: center;
}

.ProblemButton {
  height: 45px;
  margin-top: 24px;
  align-self: center;
  background-color: transparent;
  color: var(--red);
  border: 1px solid var(--red);
  opacity: 0.8;

  &:hover {
    opacity: 1;
    background-color: transparent;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .PaymentScreenshot {
    margin-top: 32px;
  }

  .CabinetButton {
    margin-top: 24px;
    align-self: unset;
  }
}
