@use '../../../../app/styles/fonts';

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &Inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 12px;
  }
}

.CabinetButton {
  min-width: 200px;
}

.ExitButton {
  width: auto;
  @include fonts.inter_16_regular;
  color: rgb(192, 22, 22);

  &:hover {
    color: rgb(153, 6, 6) !important;
  }
}

.Footer {
  padding: 0 30px;
  width: 100%;
}

.Rating {
  & > svg {
    width: 15px;
    height: 32px;
  }

  &Wrapper {
    display: flex;
    gap: 5px;
  }

  &Details {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &Value {
    @include fonts.inter_16_medium;
  }

  &Description {
    @include fonts.inter_12_regular;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Form {
    &Inputs {
      gap: 24px;
      padding: 24px 16px;
    }
  }

  .CabinetButton {
    min-width: 200px;
    width: 100%;
  }

  .Footer {
    padding: 0;
    gap: 30px;
    width: 100%;
  }

  .ExitButton {
    line-height: 18px;
  }
}
