@use '../../../app/styles/fonts';

.Tabs {
  display: flex;
  border-bottom: 1px solid rgba(209, 210, 214, 1);
  white-space: nowrap;
  width: 100%;
  overflow: auto;
}

.Tab {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 12px 16px 12px;
  cursor: pointer;
  transition: 0.2s all;
  border-bottom: 3px solid transparent;
}

.Title {
  color: rgba(116, 119, 133, 1);
  transition: 0.2s color;
  @include fonts.inter_16_medium;
}

.Badge {
  padding: 4px 8px;
  border-radius: 6px;
  background-color: transparent;
  color: rgba(116, 119, 133, 1);
  transition: 0.2s all;
  @include fonts.inter_14_medium;
}

.isActive {
  border-color: rgba(5, 8, 22, 1);

  .Title {
    color: rgba(5, 8, 22, 1);
  }

  .Badge {
    background-color: rgba(5, 8, 22, 1);
    color: rgba(255, 255, 255, 1);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Tab {
    padding: 0px 8px 16px 8px;
  }

  .Title {
    @include fonts.inter_14_medium;
  }
}
