@use '../../../../app/styles/components.scss' as *;
@use '../../../../app/styles/fonts.scss';

.CabinetLinks {
  width: 100%;
  max-width: 500px;
  gap: 24px;
}

.Link {
  display: flex;
  gap: 12px;
  border-radius: 24px;
  padding: 24px;
  background-color: #ffffff;

  &__title {
    @include fonts.inter_20_medium;
  }

  &__desc {
    color: var(--grey);
    @include fonts.inter_14_medium;
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__button {
    @extend %button;
    @include fonts.inter_14_medium;

    color: var(--white);
    background-color: var(--blue);
    height: 40px;
    border-radius: 10px;
    text-decoration: none;

    &:hover {
      background-color: #2e2ec4;
    }

    &[aria-disabled='true'] {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        background-color: var(--blue);
      }
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    img {
      max-height: 150px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .CabinetLinks {
    max-width: unset;
    gap: 20px;
  }

  .Link {
    align-items: flex-start;
    flex-direction: column-reverse;

    &__info {
      gap: 20px;
    }

    &__info {
      width: 100%;
    }

    &__button {
      width: 100%;
    }

    &__img img {
      max-height: 88px;
    }
  }
}
