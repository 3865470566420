@use '../../../../app/styles/fonts.scss';

.FAQ {
  display: flex;
  flex-direction: column;
  padding: 0 320px;
  gap: 56px;
}

.Title {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  @include fonts.hauss_64_medium;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .FAQ {
    padding: 0 20px;
    gap: 40px;
  }

  .Title {
    @include fonts.hauss_32_medium;
  }
}
