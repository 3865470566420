@use "../../../../app/styles/fonts";

.AuthBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 496px;
  background-color: rgba(255, 255, 255, 1);
  padding: 40px 20px 24px 20px;
  border-radius: 40px;
}

.Title {
  color: rgba(15, 20, 20, 1);
  @include fonts.hauss_24_bold;
}

.Container {
  margin-top: 24px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .AuthBlock {
    padding: 32px 16px 20px 16px;
    border-radius: 24px;
  }

  .Title {
    @include fonts.hauss_20_bold;
  }
}
