@use "../../../app/styles/fonts";

.PageTitle {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_32_medium;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .PageTitle {
    @include fonts.inter_24_medium;
  }
}
