@use "../../../../app/styles/fonts.scss";
@use "../../../../app/styles/components" as *;

.TelegramLink {
  @extend %button;
  gap: 12px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  @include fonts.hauss_18_medium;

  path {
    fill: rgba(255, 255, 255, 0.6);
    transition: 0.2s;
  }

  &:hover, &:active {
    color: var(--white);

    path {
      fill: var(--white);
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .TelegramLink {
    flex-direction: column;
    text-align: center;
    @include fonts.hauss_14_medium;
    line-height: 19px;
  }
}
