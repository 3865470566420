@use '../../../../app/styles/fonts';
@use '../../../../app/styles/components' as *;

.Form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.InputsContainer {
  padding: 32px;
  gap: 24px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

.Label {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.Description {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_13_regular;
}

.Text {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_16_regular;
}

.Link {
  color: rgba(14, 14, 242, 1);
  @include fonts.inter_16_regular;
}

.CabinetButton {
  align-self: center;
}

.CheckButton {
  border: 1px solid var(--blue);
  color: var(--blue);
  padding: 0 14px;
  @include fonts.inter_16_regular;

  width: auto;

  &:hover {
    border: 1px solid var(--black);
  }
}

.Emojis {
  @include fonts.inter_20_regular;
}

.Stub {
  margin: 0 auto;
  margin-top: 50px;
  max-width: 450px;
  text-align: center;
  @include fonts.inter_16_regular;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .InputsContainer {
    padding: 24px 16px;
    gap: 24px;
  }

  .Label {
    @include fonts.inter_16_medium;
  }

  .Text {
    @include fonts.inter_14_regular;
  }

  .Link {
    @include fonts.inter_14_regular;
  }

  .CabinetButton {
    align-self: unset;
  }
}
