@use "../../../app/styles/fonts";

.Collection {
  @include fonts.inter_16_regular;
  color: rgba(14, 14, 242, 1);

  &__isAutomatic {
    color: rgba(116, 119, 133, 1);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Collection {
    @include fonts.inter_14_regular;
  }
}
