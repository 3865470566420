@use "../../../app/styles/fonts";
@use "../../../app/styles/components" as *;

.СollectionButton {
  @extend %button;
  height: 40px;
  color: rgba(5, 8, 22, 1);
  gap: 8px;
  padding: 0 16px;
  border-radius: 24px;
  @include fonts.inter_14_medium;
  white-space: nowrap;

  path {
    fill: rgba(0, 0, 0, 1);
  }

  &__kindStart {
    background-color: rgba(26, 234, 172, 0.2);

    &:hover, &:active {
      background-color: rgba(26, 234, 172, 0.4);
    }
  }

  &__kindStop {
    background-color: rgba(255, 61, 40, 0.2);

    &:hover, &:active {
      background-color: rgba(255, 61, 40, 0.4);
    }
  }
}
