@use '../../../../app/styles/fonts';

.Block {
  gap: 24px;
}

.CabinetButton {
  margin-top: 32px;
  align-self: center;
}

.Comment {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  white-space: pre-line;
  @include fonts.inter_16_regular;

  &Title {
    @include fonts.inter_18_medium;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Block {
    gap: 20px;
  }

  .CabinetButton {
    margin-top: 24px;
    align-self: unset;
  }
}
