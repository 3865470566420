@use '../../../../app/styles/fonts.scss';

.InputsContainer {
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  gap: 32px;
}

.Avatar {
  width: 116px;
  height: 116px;
  border-radius: 16px;
}

.MediaTitle {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.Rating {
  & > svg {
    width: 15px;
    height: 32px;
  }

  &Wrapper {
    display: flex;
    gap: 8px;
  }

  &Details {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &Value {
    @include fonts.inter_16_medium;
  }

  &Description {
    @include fonts.inter_12_regular;
  }
}
