@use '../../styles/variables';
@use '../../styles/fonts';

@font-face {
  font-family: 'ALS Hauss Regular';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../fonts/ALSHauss-Regular.woff') format('woff'),
    url('../../fonts/ALSHauss-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'ALS Hauss Medium';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../fonts/ALSHauss-Medium.woff') format('woff'),
    url('../../fonts/ALSHauss-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'ALS Hauss Bold';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../fonts/ALSHauss-Bold.woff') format('woff'),
    url('../../fonts/ALSHauss-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter Regular';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../fonts/Inter-Regular.woff') format('woff'),
    url('../../fonts/Inter-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter Medium';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../fonts/Inter-Medium.woff') format('woff'),
    url('../../fonts/Inter-Medium.ttf') format('ttf');
}

html {
  // background-color: var(--black);
  background-color: rgba(245, 245, 247, 1);
}

body {
  height: 100dvh;

  div[id='root'] {
    height: 100%;
  }
}

.OZON {
  color: #0e0ef2;
}

.WB {
  color: #9f07e9;
}

.GOLDAPPLE {
  color: #dbfe01;
}

.YANDEX {
  color: #ff5226;
}

.loader {
  width: 50px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #171c34 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}
