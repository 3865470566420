@use "../../../app/styles/fonts";

.Customer {
  padding: 20px 16px;
  color: rgba(5, 8, 22, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  width: 320px;
  gap: 20px;
}

.Image {
  width: 56px;
  height: 56px;
}

.Name {
  @include fonts.inter_16_medium;
}

.Title {
  @include fonts.inter_14_regular;
}

.Value {
  @include fonts.inter_14_medium;
}

.withPointerCursor {
  cursor: pointer;
}
