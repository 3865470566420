@use '../../../../app/styles/fonts';
.Item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Title {
  @include fonts.inter_14_regular;
}

.Container {
  display: flex;
  flex-direction: column;
}

.Value {
  @include fonts.inter_14_medium;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReadMore {
  cursor: pointer;
  @include fonts.inter_14_medium;
  color: var(--blue);
  align-self: flex-end;
}
