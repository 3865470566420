@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  max-width: 544px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Form {
  display: flex;
  flex-direction: column;
}

.CabinetButton {
  margin-top: 32px;
  align-self: center;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .CabinetButton {
    margin-top: 24px;
    align-self: stretch;
  }
}
