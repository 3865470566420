@use "../../../app/styles/fonts";
@use "../../../app/styles/components" as *;

.CabinetButton {
  @extend %button;
  height: 56px;
  padding: 0 24px;
  border-radius: 10px;
  background-color: rgba(5, 8, 22, 1);
  color: rgba(255, 255, 255, 1);
  @include fonts.inter_18_regular;

  &:hover, &:active {
    background-color: rgba(39, 39, 39, 1);
  }

  &:disabled {
    color: rgba(135, 135, 135, 1);
    background-color: rgba(213, 213, 213, 1);
  }
}


@media screen and (min-width: 320px) and (max-width: 1023px) {
  .CabinetButton {
    height: 48px;
    @include fonts.inter_16_regular;
  }
}
