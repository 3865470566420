@use "../../../app/styles/fonts";
@use "../../../app/styles/components" as *;

.AuthButton {
  @extend %button;
  padding: 26px;
  border-radius: 64px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 1);
  @include fonts.hauss_16_bold;

  &:hover, &:active {
    background-color: rgba(39, 39, 39, 1);
  }

  &:disabled {
    color: rgba(135, 135, 135, 1);
    background-color: rgba(213, 213, 213, 1);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .AuthButton {
    padding: 19px;
  }
}
