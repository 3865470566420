@use "../../../../app/styles/fonts";

.Numbers {
  padding: 124px 56px;
  border-radius: 72px;
  color: rgba(255, 255, 255, 1);
  background: url('../../../../shared/assets/numbers_gradient_1.svg') no-repeat center top,
              url('../../../../shared/assets/numbers_gradient_2.svg') no-repeat center bottom,
              linear-gradient(0deg, rgba(6, 102, 249, 1) 0%, rgba(41, 0, 156, 1) 100%);
}

.Title {
  text-align: center;
  @include fonts.hauss_64_medium;
}

.Items {
  display: flex;
  margin-top: 64px;
  flex-direction: column;
  gap: 20px;
}

.ItemsGroup {
  display: flex;
  gap: 16px;
}

.Item {
  padding: 56px 32px;
  border-radius: 48px;
  flex: 1;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.36) 100%);
}

.ItemNumber {
  @include fonts.hauss_64_medium
}

.ItemDescription {
  @include fonts.hauss_28_regular
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Numbers {
    padding: 64px 20px;
    border-radius: 40px;
  }

  .Title {
    @include fonts.hauss_32_medium;
  }

  .Items {
    margin-top: 40px;
    gap: 12px;
  }

  .ItemsGroup {
    flex-direction: column;
    gap: 12px;
  }

  .Item {
    padding: 24px 20px;
    border-radius: 24px;
  }

  .ItemNumber {
    @include fonts.hauss_56_medium
  }

  .ItemDescription {
    @include fonts.hauss_24_regular
  }
}
