@use '../../../../app/styles/fonts';

.OrderModal {
  width: 544px;
}

.Body {
  gap: 24px;
}

.Marketplace {
  @include fonts.inter_20_medium;
}

.Collection {
  @include fonts.inter_16_medium;
}

.CabinetActionButton {
  height: 48px;
  @include fonts.inter_16_medium;
}

.Highlighted {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: rgb(130, 130, 216);
  @include fonts.inter_16_medium;
  color: var(--white);
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Body {
    gap: 16px;
  }

  .Marketplace {
    @include fonts.inter_16_medium;
  }

  .Collection {
    @include fonts.inter_14_medium;
  }

  .CabinetActionButton {
    height: 40px;
  }
}
