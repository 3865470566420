@use '../../../../app/styles/fonts';

.Container {
  text-align: center;
}

.Text {
  margin-top: 12px;
  color: rgba(14, 14, 242, 1);
  @include fonts.inter_12_regular;
}

.Button {
  margin-top: 24px;
}

.RemainingTime {
  margin-top: 20px;
  align-self: flex-start;
  text-align: left;

  span {
    @include fonts.inter_14_regular;
    color: var(--black);
  }

  strong {
    @include fonts.inter_26_medium;
  }
}
