.Background {
  img {
    z-index: -1;
    position: absolute;
  }

  .MainBackgroundGradient1 {
    left: -250px;
    top: -140px;
  }

  .MainBackgroundGradient2 {
    right: -100px;
    top: -120px;
  }

  .TariffsBackgroundGradient1 {
    left: -420px;
  }

  .TariffsBackgroundGradient2 {
    right: -500px;
  }

  .BottomBackgroundGradient {
    bottom: 0px;
    left: -300px;
  }
}

.Video {
  margin-top: 180px;
}

.HowDoesThisWork {
  margin-top: 180px;
}

.Advantages {
  margin-top: 180px;
}

.Numbers {
  margin-top: 180px;
}

.HowWeHelp {
  margin-top: 180px;
}

.Tariffs {
  margin-top: 320px;
}

.FAQ {
  margin-top: 228px;
}

.AnyQuestions {
  margin-top: 198px;
  margin-bottom: 220px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Video {
    margin-top: 120px;
  }

  .HowDoesThisWork {
    margin-top: 120px;
  }

  .Advantages {
    margin-top: 120px;
  }

  .Numbers {
    margin-top: 120px;
  }

  .HowWeHelp {
    margin-top: 120px;
  }

  .Tariffs {
    margin-top: 128px;
  }

  .FAQ {
    margin-top: 120px;
  }

  .AnyQuestions {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .Background {
    .MainBackgroundGradient1 {
      width: 500px;
      left: -100px;
      top: -80px;
    }

    .MainBackgroundGradient2 {
      width: 600px;
      right: -50px;
      top: 120px;
    }
  }
}
