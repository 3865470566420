.ProgressBar {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 8px;
  border-radius: 2px;
  background-color: rgba(226, 226, 255, 1);
  width: 100%;

  &__stage1 {
    .Progress {
      width: 37.5%;
    }

    .Line:nth-child(1) {
      background-color: rgba(10, 91, 240, 1);
    }
  }

  &__stage2 {
    .Progress {
      width: 75%;
    }

    .Line {
      background-color: rgba(10, 91, 240, 1);
    }
  }
}

.Progress {
  position: absolute;
  height: 8px;
  border-radius: 2px;
  background: linear-gradient(90deg, rgba(41, 0, 156, 1) 0%, rgba(6, 102, 249, 1) 100%);
  left: 0;
}

.Line {
  width: 1px;
  height: 16px;
  background-color: rgba(226, 226, 255, 1);
}
