@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  gap: 32px;
}

.Text {
  @include fonts.inter_16_regular;
}
