.MainImages {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

.Image {
  width: 454px;
}

.ImageWrapper {
  position: relative;
  flex-basis: 30%;
  min-width: 0;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .MainImages {
    align-items: center;
    max-width: 336px;
  }

  .Image {
    width: 314px;
  }
}
