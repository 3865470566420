@use '../../app/styles/fonts';

.RemainingTime {
  @include fonts.inter_16_regular;
  color: var(--blue);

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Time {
  @include fonts.inter_40_medium;
}

.Error {
  color: var(--red);
}
