@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  max-width: 1440px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Tabs {
  margin-top: 24px;
  margin-bottom: 20px;
}

.FormButton {
  align-self: flex-end;
  max-width: 320px;
}
