@use '../../../app/styles/fonts';

.Unit {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  background-color: rgba(245, 245, 247, 1);
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_16_medium;
}

.InputWithUnit {
  padding-right: 8px;
}
