%button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
  background-color: transparent;
  border: none;
  transition: 0.2s;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover,
  &:active {
    outline: none;
  }
}

%visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
}
