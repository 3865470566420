@use '../../../../app/styles/fonts';

.Tabs {
  margin-top: 24px;
  margin-bottom: 20px;
}

.Status {
  margin-bottom: 20px;
  align-items: center;
  padding: 16px;
  border-radius: 16px;

  &Icon {
    color: var(--white);
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 64px;
    min-width: 64px;

    & > svg {
      width: 32px;
      height: 32px;
    }
  }

  &Text {
    color: var(--black);
    @include fonts.inter_16_medium;
  }
}

.StatusError {
  background-color: #ff3d281a;

  & > .StatusIcon {
    background-color: #ff3d28;
  }
}

.StatusWarning {
  background-color: #eda85f1a;

  & > .StatusIcon {
    background-color: #eda85f;
  }
}

.StatusSuccess {
  background-color: #1dc96c1a;

  & > .StatusIcon {
    background-color: #1dc96c;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Status {
    &Icon {
      height: 56px;
      min-width: 56px;

      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    &Text {
      @include fonts.inter_14_medium;
    }
  }

  .Tabs {
    margin-bottom: 16px;
  }
}
