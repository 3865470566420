@use '../../../app/styles/fonts';
@use '../../../app/styles/components' as *;

.LandingButton {
  @extend %button;
  text-decoration: none;
  gap: 4px;
  padding: 16px 26px;
  border-radius: 64px;
  @include fonts.hauss_18_medium;
  color: rgba(18, 18, 18, 1);
  background-color: var(--white);
  border: 1px solid transparent;

  &:hover,
  &:active {
    color: var(--white);
    background-color: rgba(179, 29, 252, 1);
  }

  &__kindGhost {
    color: var(--white);
    background-color: unset;
    border: 1px solid rgba(255, 255, 255, 0.6);

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  &__withIcon {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .LandingButton {
    text-align: center;
    padding: 14px 16px;
    @include fonts.hauss_14_medium;

    &__withIcon {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .Icon {
    height: 20px;
    width: 20px;
  }
}
