@use '../../../app/styles/fonts';

.Select {
  position: relative;

  path {
    fill: rgba(69, 73, 93, 1);
  }

  &Icon {
    transition: transform 0.2s;

    &__isOpen {
      transform: rotate(180deg);
    }
  }
}

.Input {
  padding-right: 16px;
}

.Input > input {
  &::placeholder {
    color: var(--black) !important;
  }
}

.Options {
  position: absolute;
  z-index: 1;
  margin-top: 8px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  border: 1px solid rgba(209, 210, 214, 1);
  left: 0px;
  right: 0px;
  padding: 8px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  min-height: 56px;
}

.Option {
  height: 40px;
  @include fonts.inter_16_regular;
  padding: 0 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.125s background-color;

  &:hover,
  &:active {
    background-color: rgba(5, 8, 22, 0.1);
  }

  .Text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
