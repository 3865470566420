@use '../../../../app/styles/fonts';

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.Text {
  @include fonts.inter_16_regular;

  &Plug {
    color: var(--grey);
  }
}

.List {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.Link {
  @include fonts.inter_14_medium;
}

.MediaUploadItem {
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 10px;
  width: 160px;

  & > div {
    width: auto;
    height: auto;
    aspect-ratio: 1 / 1.5;
    max-height: 320px;
  }

  & > label {
    margin-bottom: 0 !important;
    text-align: center;
  }
}

.Button {
  margin: 40px auto 0 auto;
  max-width: 200px;
}

.Comment {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  white-space: pre-line;
  @include fonts.inter_16_regular;

  &Title {
    @include fonts.inter_18_medium;
  }

  &Warning {
    color: var(--red);
    @include fonts.inter_16_regular;
  }
}

.Label {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_16_regular;
}

.Separator {
  border: 0;
  border-top: 1px solid #d1d2d6;
}
