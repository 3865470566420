@use '../../../app/styles/fonts';

.Media {
  width: 160px;
}

.Preview {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 240px;
}

.Image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}

.Video {
  width: 100%;
  height: 100%;
}

.Description {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_14_medium;
  text-align: center;
}
