@use "../../../../app/styles/fonts.scss";

.AnyQuestions {
  padding: 0 168px;
}

.Container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 64px 56px 40px 40px;
  border-radius: 72px;
  background-color: rgba(255, 255, 255, 1);
  gap: 32px;
}

.Title {
  color: rgba(0, 0, 0, 1);
  @include fonts.hauss_72_bold;
}

.Description {
  margin-top: 20px;
  color: rgba(135, 135, 135, 1);
  @include fonts.hauss_20_regular;
}

.SecondBlock {
  max-width: 456px;
  width: 100%;
  display: flex;
}

.Image {
  width: 256px;
  height: 256px;

  &Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  &Description {
    color: rgba(135, 135, 135, 1);
    @include fonts.hauss_20_regular;
  }
}

.TelegramButton {
  position: absolute;
  right: 56px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .AnyQuestions {
    padding: 0 20px;
  }

  .Container {
    position: unset;
    flex-direction: column;
    padding: 40px 20px 32px;
    border-radius: 32px;
  }

  .Title {
    @include fonts.hauss_48_bold;
    line-height: 56px;
  }

  .Description {
    margin-top: 16px;
  }

  .SecondBlock {
    max-width: 296px;
    align-self: center;
    position: relative;
  }

  .Image {
    width: 164px;
    height: 164px;

    &Description {
      display: none;
    }
  }

  .TelegramButton {
    right: 20px;
  }
}
