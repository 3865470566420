.ProductForm {
  margin-top: 24px;
  gap: 32px;
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .ProductForm {
    gap: 24px;
    padding: 24px 16px;
  }
}
