@use '../../../../app/styles/fonts';

.CabinetIntegrationPageLayout {
  max-width: 656px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Header {
  align-items: center;
  justify-content: space-between;

  .CreatedAt {
    color: rgba(116, 119, 133, 1);
    @include fonts.inter_16_regular;
  }
}

.CompletedStage {
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 32px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Header {
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;

    .CabinetGhostButton {
      align-self: stretch;
    }
  }

  .CompletedStage {
    padding: 24px 16px;
  }
}
