@use '../../../../app/styles/fonts';

.Modal {
  @include fonts.inter_16_regular;
  width: 424px;
}

.Buttons {
  margin-top: 24px;
  width: 232px;
}
