.InputsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.AuthButton {
  margin-top: 40px;
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .InputsContainer {
    gap: 24px;
  }

  .AuthButton {
    margin-top: 32px;
  }
}
