@use '../../../../app/styles/fonts';

.Blocks {
  gap: 24px;
  flex-wrap: wrap;
}

.Block {
  width: 100%;
  min-width: 320px;
  flex: 1;
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  height: fit-content;
  gap: 16px;
}

.Title {
  color: rgb(23, 28, 52);
  @include fonts.inter_22_medium;
}

.BlogInfo__title {
  margin-bottom: 16px;
}

.Separator {
  display: flex;
  align-items: center;
  justify-content: center;

  hr {
    width: 100%;
    border: 0;
    margin: 10px 0;
    border-top: 1px solid rgba(209, 210, 214, 1);
  }

  span {
    @include fonts.inter_14_regular;
    padding: 0 20px;
  }
}

.BankDetails {
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Title {
    color: rgb(23, 28, 52);
    @include fonts.inter_20_medium;
  }
}
