@use '../../../../app/styles/fonts';

.RejectModal {
  width: 424px;
}

.Avatar {
  width: 56px;
  height: 56px;
}

.FirstName {
  @include fonts.inter_16_medium;
}

.instagram {
  @include fonts.inter_14_regular;
}

.Buttons {
  width: 232px;
}
