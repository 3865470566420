@use '../../../../app/styles/fonts';

.Tariffs {
  padding: 0 56px;
}

.TitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Title {
  color: rgba(255, 255, 255, 1);
  @include fonts.hauss_80_bold;
}

.Container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  justify-content: stretch;
  gap: 40px 16px;
}

.TariffPremium {
  grid-column: 1 / span 3;
}

.LandingButton {
  height: 80px;
  @include fonts.hauss_20_medium;
  padding: 16px 40px !important;
}

.CollectionTypes {
  margin-top: 180px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.CollectionType {
  max-width: 544px;
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  border-radius: 48px;

  &.AutoCollect {
    background-color: #ffffff;
    gap: 24px;
  }

  &.ManualCollect {
    color: rgba(255, 255, 255, 1);
    background: linear-gradient(0deg, rgba(132, 26, 93, 1) 0%, rgba(46, 5, 32, 1) 100%);
    gap: 32px;
  }

  &__title {
    @include fonts.hauss_48_bold;
    line-height: 60px;
  }

  &__text {
    @include fonts.hauss_20_regular;
  }
}

.ManualCollect__header {
  display: flex;
  align-items: center;
  gap: 24px;

  svg {
    min-width: 104px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Tariffs {
    padding: 0 20px;
  }

  .Title {
    @include fonts.hauss_40_bold;
  }

  .LandingButton {
    display: none;
  }

  .Container {
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 20px;
  }

  .TariffPremium {
    grid-column: unset;
  }

  .CollectionTypes {
    margin-top: 120px;
    flex-direction: column;
    gap: 20px;
  }

  .CollectionType {
    padding: 24px 20px;
    max-width: none;

    &__title {
      @include fonts.hauss_32_bold;
      line-height: 40px;
    }

    &__text {
      @include fonts.hauss_18_regular;
    }
  }

  .ManualCollect__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    svg {
      min-width: unset;
      width: 64px;
      height: 64px;
    }
  }
}
