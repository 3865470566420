@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  box-sizing: content-box;
  margin: 0 auto;
}
