@use '../../../../app/styles/fonts';

.MenuContent {
  position: fixed;
  inset: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 1);
}

.Header {
  height: 68px;
  background-color: rgba(255, 255, 255, 1);
  padding: 0 20px;
  border-bottom: 1px solid rgba(209, 210, 214, 1);

  .CloseIcon {
    margin-left: auto;
    color: var(--grey);
  }
}

.Container {
  padding: 32px;
  overflow: auto;
}

.Line {
  height: 1px;
  background-color: rgba(209, 210, 214, 1);
  flex-shrink: 0;
}

.NavigationItem {
  text-decoration: none;
  @include fonts.inter_24_medium;
  color: rgba(5, 8, 22, 1);
}

.Balance {
  @include fonts.inter_24_medium;
  color: rgba(14, 14, 242, 1);
}
