.Rating {
  display: flex;
  gap: 4px;
}

.Star {
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }

  &[aria-disabled='true'] {
    cursor: default;
  }
}
