@use '../../../../app/styles/fonts';

.FormButton {
  align-self: center;
}

.Comment {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 8px;
  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  white-space: pre-line;
  @include fonts.inter_16_regular;
}

.RemainingTime strong {
  @include fonts.inter_26_medium;
}
