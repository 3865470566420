@use '../../../../app/styles/fonts';

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_24_medium;
}

.Tasks {
  margin-top: 36px;
}

.Loader {
  margin-top: 20px;
  align-self: center;
}

.Tabs {
  margin: 20px 0;
}
