@use '../../../app/styles/fonts';

.PhoneInput {
  display: flex;

  &__landing {
    gap: 16px;

    .Code {
      padding: 21px;
      border-radius: 16px;
      color: rgba(135, 135, 135, 1);
      background-color: rgba(239, 239, 239, 1);
      @include fonts.hauss_16_medium;
      line-height: 19px;
    }
  }

  &__cabinet {
    background-color: var(--white);
    border: 1px solid rgba(209, 210, 214, 1);
    border-radius: 10px;

    &_small {
      .Code {
        width: 32px;
      }
    }

    .Code {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 6px 8px 6px 6px;
      width: 40px;
      flex-shrink: 0;
      background-color: rgba(245, 245, 247, 1);
      border-radius: 6px;
      @include fonts.inter_16_regular;
    }

    .NumericInput {
      padding-left: 0;
      border: none;
    }

    &.isInvalid {
      border-color: rgba(255, 79, 60, 1);
    }
  }
}

.NumericInput {
  flex-grow: 1;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .PhoneInput {
    &__landing {
      gap: 12px;

      .Code {
        padding: 17px;
        line-height: 17px;
      }
    }
  }
}
