@use '../../../app/styles/fonts';

.CabinetPageLayout {
  max-width: 576px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Header {
  align-items: center;
  justify-content: space-between;

  box-sizing: content-box;
}

.Blogger {
  width: 100%;
  margin: 0;
}

.Section {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 32px;
}

.Title {
  margin-bottom: 12px;
  @include fonts.inter_18_medium;
}

.Rating {
  label {
    margin-bottom: 0 !important;
  }

  flex-direction: row;
  gap: 12px;
  @include fonts.inter_16_regular;
}

.Comment {
  margin-top: 12px;
  textarea {
    resize: none;
  }
}
