@use '../../../app/styles/fonts';

.Blogger {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 16px;
  color: rgba(5, 8, 22, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  width: 320px;
  gap: 20px;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.Header {
  text-decoration: none;
  color: var(--black);

  display: flex;
  gap: 16px;
  align-items: center;

  .Image {
    width: 56px;
    height: 56px;
  }

  .Info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .Name {
    @include fonts.inter_16_medium;
  }

  .instagram {
    @include fonts.inter_14_regular;
  }
}

.Body {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .Item {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }

  .Title {
    @include fonts.inter_14_regular;
  }

  .Value {
    @include fonts.inter_14_medium;
  }

  .Tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .Tag {
    display: flex;
    height: 24px;
    border-radius: 12px;
    padding: 0 8px;
    background-color: rgba(241, 241, 241, 1);
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include fonts.inter_12_medium;
  }
}

.withPointerCursor {
  cursor: pointer;
}

.TelegramLink {
  color: var(--blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.Id {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fonts.inter_12_medium;
  min-width: 32px;
  position: absolute;
  left: -12px;
  top: -8px;
  border-radius: 16px;
  background-color: rgb(226, 226, 255);
  opacity: 0.9;
  padding: 6px 8px;
}

.Rating {
  & > svg {
    width: 15px;
    height: 32px;
  }

  &Wrapper {
    display: flex;
    gap: 8px;
  }

  &Details {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &Value {
    @include fonts.inter_16_medium;
  }

  &Description {
    @include fonts.inter_12_regular;
  }
}
