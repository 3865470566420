@use '../../../../app/styles/fonts';

.Layout {
  gap: 24px;
}

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_24_medium;
}

.Orders {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 0.4fr));
  gap: 16px;
  margin-top: 24px;
}

.Loader {
  margin-top: 20px;
  align-self: center;
}

@media screen and (min-width: 375px) and (max-width: 850px) {
  .Orders {
    grid-template-columns: repeat(auto-fit, minmax(150px, 0.5fr));
  }
}

@media screen and (max-width: 375px) {
  .Orders {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Orders {
    margin-top: 20px;
  }
}
