@use '../../../../app/styles/fonts';

.Form {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &Inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 12px;
  }
}

.CabinetButton {
  min-width: 200px;
}

.Footer {
  padding: 0 30px;
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Form {
    &Inputs {
      gap: 24px;
      padding: 24px 16px;
    }
  }

  .CabinetButton {
    min-width: 200px;
    width: 100%;
  }

  .Footer {
    padding: 0;
    gap: 30px;
    width: 100%;
  }
}
