@use "../../../../app/styles/fonts";

.TelegramLink {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 14px;
  color: rgba(116, 119, 133, 1);
  text-decoration: none;
  border-radius: 8px;
  transition: 0.2s;
  @include fonts.inter_16_regular;

  path {
    fill: rgba(116, 119, 133, 1);
    transition: 0.2s;
  }

  &:hover, &:active {
    color: rgba(14, 14, 242, 1);
    background-color: rgba(245, 245, 247, 1);

    path {
      fill: rgba(14, 14, 242, 1);
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .TelegramLink {
    gap: 8px;
    padding: 0;
    color: rgba(5, 8, 22, 1);
    @include fonts.inter_22_medium;

    svg {
      width: 32px;
      height: 32px;
    }

    path {
      fill: rgba(5, 8, 22, 1);
      transition: 0.2s;
    }
  }
}
