@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  max-width: 1440px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Header {
  margin-bottom: 24px;
}

.SubTitle {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_16_regular;
}

.InputsContainer {
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

.Avatar {
  width: 116px;
  height: 116px;
  border-radius: 16px;
}

.MediaTitle {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.Buttons {
  margin-top: 32px;
  width: 288px;
  align-self: center;
}
