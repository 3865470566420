@use '../../../app/styles/components.scss' as *;
@use '../../../app/styles/fonts';

.Checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  outline: none;
  cursor: pointer;

  &_disabled {
    cursor: default;
  }
}

.Input {
  @extend %visually-hidden;
}

.Icon {
  path {
    fill: rgba(255, 255, 255, 1);
  }
}

.Text {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_16_regular;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Text {
    @include fonts.inter_14_regular;
  }
}
