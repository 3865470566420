@use '../../../../app/styles/fonts';

.Layout {
  max-width: 1024px;
  margin: 0 auto;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  &Text {
    width: 100%;
  }
}

.AddButton {
  @include fonts.inter_18_regular;
}

.Text {
  margin: 150px auto 0 auto;
  @include fonts.inter_20_regular;
  text-align: center;
}

.Main {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.Group {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &Title {
    @include fonts.inter_20_medium;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Group {
    &Title {
      @include fonts.inter_18_medium;
      margin-bottom: 4px;
    }
  }
}
