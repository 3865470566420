@use '../../../../app/styles/fonts';

.Header {
  margin-bottom: 12px;
}

.Products {
  margin-top: 24px;
  gap: 16px;
}

.Loader {
  margin-top: 20px;
  align-self: center;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Products {
    margin-top: 20px;
    gap: 20px;
  }

  .AddButton {
    margin-top: 24px;
    height: 48px;
    padding: 0 16px;

    span {
      display: block;
    }
  }
}
