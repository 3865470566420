@use "../../../../app/styles/fonts";

.BloggersCount {
  padding: 0 56px;
}

.Container {
  padding: 104px 176px;
  border-radius: 200px;
  border: 1px solid rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(5, 8, 22, 1) 0%, rgba(55, 55, 55, 1) 100%);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  @include fonts.hauss_88_medium;
}

.Numbers {
  color: rgba(26, 234, 122, 1);
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .BloggersCount {
    padding: 0 20px;
  }

  .Container {
    padding: 88px 24px;
    border-radius: 80px;
    @include fonts.hauss_26_medium;
  }
}
