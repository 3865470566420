@use '../../../../app/styles/fonts';

.Order {
  position: relative;
  height: 348px;
  // width: 208px;
  max-width: 300px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  cursor: pointer;
}

.Photo {
  height: 272px;
  border-radius: 0 12px 0 0;
  object-fit: cover;
  width: 100%;
}

.Info {
  flex: 1;
  padding: 12px;
  color: rgba(5, 8, 22, 1);
}

.Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include fonts.inter_13_regular;
}

.Price {
  @include fonts.inter_16_medium;
}

.BonusPrice {
  padding: 8px;
  border-radius: 12px;
  background-color: #e2e2ff;
  position: absolute;
  top: 8px;
  right: 8px;
  text-align: end;
  @include fonts.inter_12_regular;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

  strong {
    @include fonts.inter_14_medium;
  }
}

.Id {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fonts.inter_12_medium;
  min-width: 32px;
  position: absolute;
  left: -12px;
  top: -8px;
  border-radius: 16px;
  background-color: rgb(226, 226, 255);
  padding: 6px 8px;
}

@media screen and (min-width: 375px) and (max-width: 1023px) {
  .Order {
    height: 297px;
    // width: 150px;
    border-radius: 8px;
  }

  .Photo {
    height: 210px;
  }

  .Title {
    @include fonts.inter_13_regular;
  }
}
