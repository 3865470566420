@use '../../../../app/styles/fonts';

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.Text {
  @include fonts.inter_16_regular;

  &Plug {
    color: var(--grey);
  }
}

.List {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.Label {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_16_regular;
}

.Link {
  @include fonts.inter_14_medium;
}

.Separator {
  border: 0;
  border-top: 1px solid #d1d2d6;
}
