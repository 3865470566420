@mixin hauss_regular {
  font-family: var(--font-family-als-hauss-regular);
  font-style: normal;
  font-weight: 400;
}

@mixin hauss_10_regular {
  @include hauss_regular;
  font-size: 10px;
  line-height: 12px;
}
@mixin hauss_12_regular {
  @include hauss_regular;
  font-size: 12px;
  line-height: 16px;
}

@mixin hauss_16_regular {
  @include hauss_regular;
  font-size: 16px;
  line-height: 22.4px;
}

@mixin hauss_18_regular {
  @include hauss_regular;
  font-size: 18px;
  line-height: 26px;
}

@mixin hauss_20_regular {
  @include hauss_regular;
  font-size: 20px;
  line-height: 28px;
}

@mixin hauss_24_regular {
  @include hauss_regular;
  font-size: 24px;
  line-height: 32px;
}

@mixin hauss_28_regular {
  @include hauss_regular;
  font-size: 28px;
  line-height: 37.18px;
}

@mixin hauss_32_regular {
  @include hauss_regular;
  font-size: 32px;
  line-height: 42.5px;
}

@mixin hauss_medium {
  font-family: var(--font-family-als-hauss-medium);
  font-style: normal;
  font-weight: 500;
}

@mixin hauss_12_medium {
  @include hauss_medium;
  font-size: 12px;
  line-height: 16px;
}

@mixin hauss_14_medium {
  @include hauss_medium;
  font-size: 14px;
  line-height: 12px;
}

@mixin hauss_16_medium {
  @include hauss_medium;
  font-size: 16px;
  line-height: 21px;
}

@mixin hauss_18_medium {
  @include hauss_medium;
  font-size: 18px;
  line-height: 20px;
}

@mixin hauss_20_medium {
  @include hauss_medium;
  font-size: 20px;
  line-height: 27px;
}

@mixin hauss_24_medium {
  @include hauss_medium;
  font-size: 24px;
  line-height: 32px;
}

@mixin hauss_26_medium {
  @include hauss_medium;
  font-size: 26px;
  line-height: 36.4px;
}

@mixin hauss_32_medium {
  @include hauss_medium;
  font-size: 32px;
  line-height: 38.4px;
}

@mixin hauss_56_medium {
  @include hauss_medium;
  font-size: 56px;
  line-height: 74.37px;
}

@mixin hauss_64_medium {
  @include hauss_medium;
  font-size: 64px;
  line-height: 77px;
}

@mixin hauss_72_medium {
  @include hauss_medium;
  font-size: 72px;
  line-height: 95.62px;
}

@mixin hauss_88_medium {
  @include hauss_medium;
  font-size: 88px;
  line-height: 105.6px;
}

@mixin hauss_bold {
  font-family: var(--font-family-als-hauss-bold);
  font-style: normal;
  font-weight: 700;
}

@mixin hauss_16_bold {
  @include hauss_bold;
  font-size: 16px;
  line-height: 17.6px;
}

@mixin hauss_18_bold {
  @include hauss_bold;
  font-size: 18px;
  line-height: 19.8px;
}

@mixin hauss_20_bold {
  @include hauss_bold;
  font-size: 20px;
  line-height: 26.56px;
}

@mixin hauss_24_bold {
  @include hauss_bold;
  font-size: 24px;
  line-height: 32px;
}

@mixin hauss_32_bold {
  @include hauss_bold;
  font-size: 32px;
  line-height: 42.5px;
}

@mixin hauss_40_bold {
  @include hauss_bold;
  font-size: 40px;
  line-height: 48px;
}

@mixin hauss_48_bold {
  @include hauss_bold;
  font-size: 48px;
  line-height: 64px;
}

@mixin hauss_64_bold {
  @include hauss_bold;
  font-size: 64px;
  line-height: 70px;
}

@mixin hauss_72_bold {
  @include hauss_bold;
  font-size: 72px;
  line-height: 72px;
}

@mixin hauss_80_bold {
  @include hauss_bold;
  font-size: 80px;
  line-height: 96px;
}

@mixin inter_regular {
  font-family: var(--font-family-inter-regular);
  font-style: normal;
  font-weight: 400;
}

@mixin inter_10_regular {
  @include inter_regular;
  font-size: 10px;
  line-height: 12px;
}

@mixin inter_12_regular {
  @include inter_regular;
  font-size: 12px;
  line-height: 14.52px;
}

@mixin inter_13_regular {
  @include inter_regular;
  font-size: 13px;
  line-height: 15.73px;
}

@mixin inter_14_regular {
  @include inter_regular;
  font-size: 14px;
  line-height: 16.94px;
}

@mixin inter_16_regular {
  @include inter_regular;
  font-size: 16px;
  line-height: 24px;
}

@mixin inter_18_regular {
  @include inter_regular;
  font-size: 18px;
  line-height: 28px;
}

@mixin inter_20_regular {
  @include inter_regular;
  font-size: 20px;
  line-height: 32px;
}

@mixin inter_medium {
  font-family: var(--font-family-inter-medium);
  font-style: normal;
  font-weight: 500;
}

@mixin inter_12_medium {
  @include inter_medium;
  font-size: 12px;
  line-height: 14.52px;
}

@mixin inter_14_medium {
  @include inter_medium;
  font-size: 14px;
  line-height: 16.94px;
}

@mixin inter_16_medium {
  @include inter_medium;
  font-size: 16px;
  line-height: 19.36px;
}

@mixin inter_18_medium {
  @include inter_medium;
  font-size: 18px;
  line-height: 21.78px;
}

@mixin inter_20_medium {
  @include inter_medium;
  font-size: 20px;
  line-height: 24.2px;
}

@mixin inter_22_medium {
  @include inter_medium;
  font-size: 22px;
  line-height: 26.63px;
}

@mixin inter_24_medium {
  @include inter_medium;
  font-size: 24px;
  line-height: 33.6px;
}

@mixin inter_26_medium {
  @include inter_medium;
  font-size: 26px;
  line-height: 31.47px;
}

@mixin inter_32_medium {
  @include inter_medium;
  font-size: 32px;
  line-height: 38.73px;
}
@mixin inter_40_medium {
  @include inter_medium;
  font-size: 40px;
  line-height: 48.41px;
}
