@use '../../../../app/styles/fonts';

.Rating {
  background-color: rgb(255, 255, 255);
  padding: 32px;
  border-radius: 12px;
  margin-top: 16px;
  gap: 12px;

  &Title {
    margin-bottom: 12px;
    @include fonts.inter_18_medium;
  }

  &Item {
    flex-direction: column;
    gap: 10px;

    &Title {
      @include fonts.inter_14_medium;
    }
  }

  &Comment {
    margin-top: 12px;
    gap: 4px;
  }

  &Button {
    margin-top: 12px;
    @include fonts.inter_16_medium;
    max-width: 200px;
    height: 44px;
  }

  &Gratitude {
    text-align: center;
    @include fonts.inter_16_medium;
  }
}
