.Flex {
  display: flex;
  min-width: 0;
}

.isFluid {
  width: 100%;
  height: 100%;
}

.isInline {
  display: inline-flex;
}
