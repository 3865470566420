@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  width: 100%;
  max-width: 576px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Topics {
  margin-top: 24px;
  padding: 32px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
}

.CloseIcon {
  cursor: pointer;
  color: var(--grey);
}

.AddButton {
  width: unset;
  padding: 0 10px;
  align-self: flex-start;
}

.Error {
  color: rgba(255, 79, 60, 1);
  @include fonts.inter_13_regular;
}
