@use 'node_modules/normalize.css/normalize.css';

html {
  scroll-behavior: smooth;
}

*,
h1 {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.alphaText {
  color: rgba(255, 255, 255, 0.6);
}

.preLineText {
  white-space: pre-line;
}

.visibility-hidden {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}

.FakeInput {
  opacity: 0;
  position: absolute;
  z-index: -99;
}
