@use "../../../app/styles/fonts";
@use "../../../app/styles/components" as *;

.CabinetGhostButton {
  @extend %button;
  gap: 4px;
  height: 48px;
  border-radius: 10px;
  padding: 0 16px;
  border: 1px solid rgba(5, 8, 22, 1);
  @include fonts.inter_16_regular;

  path {
    transition: 0.2s;
  }

  &:hover, &:active {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(5, 8, 22, 1);

    path {
      stroke: var(--white);
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .CabinetGhostButton {
    height: 40px;
    padding: 8px;
  }

  .withIcon {
    span {
      display: none;
    }
  }
}
