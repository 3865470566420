// TODO Возможно можно использовать гриды в этом блоке
.HeaderButtons {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__reverse {
    flex-direction: column-reverse;
  }
}

.Group {
  display: flex;
  flex-direction: row;
  gap: 24px
}

.LandingButton {
  width: 260px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .HeaderButtons {
    flex-direction: row;
    gap: 16px;

    &__reverse {
      flex-direction: row-reverse;
    }
  }

  .Group {
    flex-direction: column;
    gap: 32px;
  }

  .LandingButton {
    width: unset;
  }
}
