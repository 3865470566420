@use '../../../../app/styles/fonts';

.Header {
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .Main {
    gap: 12px;

    .Photo {
      object-fit: cover;
      border-radius: 8px;
      width: 56px;
      height: 56px;
    }

    .Marketplace {
      color: rgba(5, 8, 22, 1);
      @include fonts.inter_16_regular;
    }

    .Title {
      display: inline-flex;
      align-items: center;
      color: rgba(5, 8, 22, 1);
      @include fonts.inter_18_medium;
    }
  }
}

.Tabs {
  margin-top: 24px;
}

.Bloggers {
  margin-top: 20px;
}

.Link {
  cursor: pointer;
  @include fonts.inter_16_regular;
  color: var(--blue);

  &:hover {
    text-decoration: underline;
  }
}

.Download {
  color: var(--blue);
  border-radius: 8px;
  cursor: pointer;
  @include fonts.inter_16_regular;
  display: flex;
  gap: 4px;
  align-items: center;
  align-self: flex-end;

  &:hover {
    text-decoration: underline;
  }
}

.Id {
  @include fonts.inter_12_medium;
  border-radius: 16px;
  background-color: rgba(226, 226, 255, 0.9);
  padding: 4px 8px;
  margin-left: 8px;
}

.ConfirmedBlogger {
  position: relative;
}

.Warning {
  cursor: pointer;
  width: 30px;
  height: 30px;

  position: absolute;
  right: 16px;
  top: 20px;

  transition: 0.125s all;
  &:hover {
    scale: 1.1;
  }
}

.AdminWarning {
  width: 24px;
  height: 24px;
  top: 50px;
}

.RemainingTime {
  span {
    @include fonts.inter_14_regular;
    color: var(--black);
  }

  strong {
    @include fonts.inter_26_medium;
  }
}

.EmptyState {
  margin: 0 auto;
  margin-top: 60px;
  align-self: center;
  max-width: 400px;
  text-align: center;

  h2 {
    @include fonts.inter_24_medium;
  }
}

.BlockButton {
  margin-top: -12px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Header {
    flex-direction: column;
    align-items: start;

    .Main {
      gap: 16px;

      .Photo {
        min-width: 64px;
        height: 64px;
      }

      .Title {
        @include fonts.inter_16_medium;
      }
    }
  }

  .Tabs {
    margin-top: 32px;
  }

  .Bloggers {
    margin-top: 16px;
  }

  .Link {
    @include fonts.inter_14_regular;
  }

  .EmptyState {
    h2 {
      @include fonts.inter_20_medium;
    }
  }
}
