.Header {
  display: flex;
  border-bottom: 1px solid var(--white02);
  justify-content: center;
  z-index: 1;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 56px;
  max-width: 1440px;
  flex: 1;
}

.Buttons {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Container {
    padding: 16px 20px;
  }

  .Buttons {
    gap: 12px;
  }
}
