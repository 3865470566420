@use '../../../../app/styles/fonts';

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_24_medium;
}

.Tabs {
  margin: 24px 0;
}

.Products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 0.4fr));
  gap: 16px;
  margin-top: 32px;
}

.Product {
  width: auto;
  max-width: 350px;
}

.Loader {
  margin-top: 20px;
  align-self: center;
}

@media screen and (min-width: 450px) and (max-width: 850px) {
  .Products {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .Product {
    img {
      height: 250px;
    }
  }
}

@media screen and (max-width: 450px) {
  .Products {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
