@use '../../../../app/styles/fonts';
.Blocks {
  gap: 24px;
  flex-wrap: wrap;
}

.Block {
  width: 100%;
  min-width: 320px;
  flex: 1;
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  height: fit-content;
  gap: 16px;
}

.BlogInfo {
  max-width: 464px;
}

.Buttons {
  width: 100%;
  max-width: 300px;
  align-self: center;
}

.Title {
  color: rgb(23, 28, 52);
  @include fonts.inter_22_medium;
}
