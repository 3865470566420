@use '../../../../app/styles/fonts.scss';

.CabinetFAQ {
  max-width: 800px;
  gap: 24px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .CabinetFAQ {
    max-width: unset;
    gap: 20px;
  }
}
