@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  max-width: 544px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Form {
  display: flex;
  flex-direction: column;
}

.InputsContainers {
  margin-top: 24px;
  gap: 16px;
}

.InputsContainer {
  gap: 32px;
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

.Checkboxes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.CabinetButton {
  margin-top: 32px;
  align-self: center;
}

.Explanation {
  @include fonts.inter_16_regular;
}

.BonusPrice {
  &__postfix {
    padding-right: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .InputsContainers {
    gap: 24px;
  }

  .InputsContainer {
    gap: 24px;
    padding: 24px 16px;
  }

  .CabinetButton {
    margin-top: 24px;
    align-self: stretch;
  }
}
