@use '../../../../app/styles/fonts';

.HowDoesThisWork {
  padding: 80px 56px;
  border-radius: 72px;
  position: relative;
  overflow: hidden;
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(0deg, rgba(6, 102, 249, 1) 0%, rgba(41, 0, 156, 1) 100%);
  z-index: 1;
}

.BackgroundGradient1 {
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
}

.BackgroundGradient2 {
  position: absolute;
  z-index: -1;
  bottom: 0px;
  right: 0px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Title {
  @include fonts.hauss_64_medium;
}

.Arrows {
  display: flex;
  gap: 28px;
}

.Arrow {
  cursor: pointer;
  width: 100%;
  height: 100%;
  fill: rgba(255, 255, 255, 1);
  transition: 0.2s;

  &__button {
    border-radius: 100%;
    width: 56px;
    height: 56px;
    border: 0;
    background-color: transparent;
  }

  &:hover {
    fill: rgba(255, 255, 255, 0.6);
  }
}

.List {
  margin: 0 -56px;
  padding: 0 38px 0 56px;

  margin-top: 56px;

  display: flex;
  overflow: auto;
  gap: 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Item {
  padding: 40px 24px;
  width: 432px;
  height: 438px;
  border-radius: 48px;
  background-color: rgba(255, 255, 255, 0.2);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  gap: 12px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Title {
    @include fonts.hauss_32_medium;
  }

  &Description {
    color: rgba(255, 255, 255, 0.6);
    @include fonts.hauss_20_regular;
  }

  &Number {
    color: rgba(255, 255, 255, 0.4);
    @include fonts.hauss_48_bold;
  }

  &Image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
}

.Footer {
  margin-top: 40px;
  display: none;

  .Arrows {
    margin-left: auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  $margin-sides: -20px;
  $padding-sides: 30px;

  .HowDoesThisWork {
    padding: 48px 20px;
    border-radius: 40px;
  }

  .Title {
    @include fonts.hauss_32_medium;
  }

  .BackgroundGradient1,
  .BackgroundGradient2 {
    display: none;
  }

  .Arrow {
    width: 48px;
    height: 48px;
  }

  .Header {
    .Arrows {
      display: none;
    }
  }

  .List {
    margin: 0 -20px;
    padding: 0 20px;

    margin-top: 24px;
  }

  .Item {
    padding: 40px 20px;
    width: 336px;
    height: 350px;
    border-radius: 32px;

    &Title {
      @include fonts.hauss_24_medium;
    }

    &Description {
      @include fonts.hauss_16_regular;
    }

    &Number {
      @include fonts.hauss_40_bold;
    }
  }

  .Footer {
    display: flex;
  }
}
