.Logo {
  width: 135px;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Logo {
    width: 85px;
  }
}
