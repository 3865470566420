.LandingPage {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--black);
  position: relative;
  z-index: 2;
}

.Container {
  width: 100%;
  max-width: 1440px;
  align-self: center;
  position: relative;
}
