@use '../../../../app/styles/fonts';

.Background {
  img {
    z-index: -1;
    position: absolute;
  }

  // TODO Попробовать избавиться от img компонентов и использовать background
  .AuthLayoutBackground1 {
    left: -220px;
    top: 0;
    width: calc(768px * 1.5);
    height: calc(730px * 1.5);
  }

  .AuthLayoutBackground2 {
    right: -200px;
    top: 180px;
    width: calc(716px * 1.4);
    height: calc(773px * 1.4);
  }
}

.AuthLayout {
  position: relative;
  z-index: 2;
  background-color: var(--black);

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  position: relative;
  align-items: center;
  padding: 24px 56px;
  // background: url('../../../../shared/assets/auth_layout_background_1.png') no-repeat top left -220px / calc(768px * 1.5) calc(730px * 1.5),
  //             url('../../../../shared/assets/auth_layout_background_2.png') no-repeat top 180px right -200px / calc(716px * 1.4) calc(773px * 1.4);
}

.Header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  margin-bottom: 28px;
}

.Toaster {
  @include fonts.hauss_16_regular;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Container {
    padding: 16px 20px;
  }

  .Header {
    height: 42px;
    margin-bottom: 24px;
  }
}
