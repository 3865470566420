@use '../../../../app/styles/components' as *;
@use '../../../../app/styles/fonts';

.Logo {
  @extend %button;
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_26_medium;

  text-decoration: none;

  &:hover,
  &:active {
    color: rgba(14, 14, 242, 1);
  }

  img {
    width: 160px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Logo {
    @include fonts.inter_20_medium;
  }
}
