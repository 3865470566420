@use '../../../../app/styles/fonts';

.InputsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.AuthButton {
  margin-top: 20px;
  width: 100%;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.PasswordLink {
  cursor: pointer;
  align-self: flex-end;
  background-color: var(--white);
  @include fonts.hauss_16_regular;
  text-decoration: none;

  transition: 0.125s color;
  color: var(--grey);

  &:hover {
    color: #272727;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .AuthBlock {
    margin-top: 104px;
  }

  .AuthButton {
    margin-top: 12px;
  }
}
