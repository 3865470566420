@use '../../../../app/styles/fonts';

.Advantages {
  padding: 0 56px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.Title {
  @include fonts.hauss_64_medium;
}

.Items {
  display: flex;
  margin-top: 64px;
  gap: 16px;
}

.Item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 1px 20px 0px #ffffff80 inset;
  padding: 40px 24px 32px;
  border-radius: 40px;

  svg {
    margin-left: 8px;
  }

  &.ComfortItem {
    background-color: #0e0ef2;
  }
  &.ProfitItem {
    background-color: #e45d34;
  }
  &.EffectiveItem {
    background-color: #9f07e9;
  }
}

.ItemTitle {
  margin-top: 30px;
  @include fonts.hauss_32_medium;
}

.ItemDescription {
  text-align: left;
  margin-top: 12px;
  @include fonts.hauss_20_regular;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Advantages {
    padding: 0 20px;
  }

  .Title {
    @include fonts.hauss_32_medium;
  }

  .Items {
    flex-direction: column;
    margin-top: 40px;
    gap: 20px;
  }

  .Item {
    svg {
      margin-left: 0;
    }
  }

  .ItemTitle {
    margin-top: 24px;
    @include fonts.hauss_24_medium;
  }

  .ItemDescription {
    @include fonts.hauss_16_regular;
  }
}
