@use "../../../app/styles/fonts";
@use "../../../app/styles/components" as *;

.CabinetBackButton {
  @extend %button;
  gap: 4px;
  height: 20px;
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_16_regular;

  path {
    fill: rgba(116, 119, 133, 1)
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .CabinetBackButton {
    @include fonts.inter_14_regular;
  }
}
