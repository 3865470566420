@use '../../../../app/styles/fonts';

.Layout {
  max-width: 544px;
  margin: 0 auto;
  padding: 32px 0;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.Main {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.Block {
  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 32px;
  border-radius: 12px;
  background-color: var(--white);
}

.Image {
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

.Button {
  margin: 0 auto;
  max-width: 320px;
}
