@use "../../../../app/styles/components" as *;

.IconButton {
  @extend %button;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 1px solid rgba(245, 245, 247, 1);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  transition: border-color 0.2s;

  &:hover, &:active {
    border: 2px solid rgba(116, 119, 133, 1);
  }

  &__bell {
    path {
      fill: rgba(116, 119, 133, 1);
    }
  }
}
