@use "../../../../app/styles/fonts.scss";

.Footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--white02);
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 56px;
  max-width: 1440px;
  flex: 1;
}

.Logo {
  width: 135px;
}

.Link {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;

  @include fonts.hauss_18_medium;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Container {
    padding: 24px 20px;
  }

  .Logo {
    width: 85px;
  }

  .Link {
    @include fonts.hauss_16_medium;
  }
}
