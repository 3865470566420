@use '../../../../app/styles/fonts';
.Container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.Icon svg {
  width: 140px;
  height: 140px;
}

.Title {
  @include fonts.inter_32_medium;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Container {
    gap: 16px;
  }

  .Icon {
    height: 120px;

    svg {
      width: 120px;
      height: 120px;
    }
  }

  .Title {
    max-width: 250px;
    text-align: center;
    @include fonts.inter_24_medium;
  }
}
