@use '../../../app/styles/fonts';
@use '../../../app/styles/components' as *;

.Layout {
  margin-top: 10px;
  flex-direction: column;
}

.Description {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_13_regular;
}

.Button {
  @extend %button;
  height: 40px;
  padding: 0 4px;
  color: rgba(116, 119, 133, 1);
  align-self: flex-start;
  @include fonts.inter_14_medium;
}

.Separator {
  display: flex;
  align-items: center;
  justify-content: center;

  hr {
    width: 100%;
    border: 0;
    margin: 10px 0;
    border-top: 1px solid rgba(209, 210, 214, 1);
  }

  span {
    @include fonts.inter_14_regular;
    padding: 0 20px;
  }
}
