@use "../../../app/styles/fonts";

.ValueField {
  gap: 12px;
}

.Label {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.Value {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_16_regular;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .ValueField {
    gap: 8px;
  }

  .Label {
    @include fonts.inter_16_medium;
  }

  .Value {
    @include fonts.inter_14_regular;
  }
}
