@use '../../../../app/styles/fonts.scss';

.Layout {
  display: flex;
  align-items: center;
  gap: 32px;
}

.Image {
  width: 116px;
  height: 116px;

  span {
    font-size: 32px;
  }
}

.Text {
  @include fonts.inter_16_regular;
  color: var(--grey);
  cursor: pointer;
  transition: 0.125s all;

  &:hover {
    text-decoration: underline;
  }
}

.HiddenInput {
  display: none;
}
