@use "../../../app/styles/fonts.scss";

.OrderStatus {
  gap: 16px;
}

.Status {
  color: rgba(14, 14, 242, 1);
  @include fonts.inter_14_regular;

  &__isPending {
    color: rgba(116, 119, 133, 1);
  }

  &__isCanceled {
    color: rgba(255, 61, 40, 1);
  }
}

.Progress {
  border-radius: 16px;
  background-color: rgba(226, 226, 255, 1);
  padding: 10px 12px;
  text-wrap: nowrap;
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_14_medium;

  &__isPending {
    background-color: rgba(5, 8, 22, 0.1);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .OrderStatus {
    justify-content: space-between;
    width: 100%;
  }
}
