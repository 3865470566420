@use "../../../../app/styles/fonts";

.Section {
  gap: 16px;

  .Title {
    color: rgba(5, 8, 22, 1);
    @include fonts.inter_24_medium;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Section {
    gap: 12px;

    .Title {
      @include fonts.inter_20_medium;
    }
  }
}
