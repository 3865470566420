@use '../../../../app/styles/fonts.scss';

.Main {
  position: relative;
  overflow: hidden;
}

.Container {
  padding: 64px 56px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  z-index: 1;
}

.FirstBlock {
  display: flex;
  flex-direction: column;
  max-width: 666px;
  flex: 1;
  gap: 134px;
}

.SecondBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 544px;
  flex: 1;
  gap: 56px;

  .HeaderButtons {
    display: none;
  }
}

.Title {
  color: rgba(255, 255, 255, 1);

  @include fonts.hauss_64_bold;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Container {
    padding: 32px 20px;
    flex-direction: column;
    gap: 16px;
  }

  .FirstBlock {
    max-width: unset;

    .HeaderButtons {
      display: none;
    }
  }

  .SecondBlock {
    max-width: unset;
    gap: 12px;

    .HeaderButtons {
      margin-top: 48px;
      display: flex;
    }
  }

  .Title {
    @include fonts.hauss_32_bold;
  }
}
