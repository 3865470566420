@use '../../../app/styles/fonts';

.Container {
  display: flex;
  gap: 12px;
  width: 100%;

  &__landing {
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 16px;
    color: rgba(15, 20, 20, 1);
    background-color: rgba(239, 239, 239, 1);
    @include fonts.hauss_16_medium;
    line-height: 19px;

    transition: 0.125s border-color;

    .Input::placeholder {
      color: rgba(135, 135, 135, 1);
    }

    &:not(:disabled):not(.isInvalid) {
      &:hover,
      &:focus-within {
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__cabinet {
    align-items: center;
    height: 56px;
    border: 1px solid rgba(209, 210, 214, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    color: var(--black);
    @include fonts.inter_16_regular;
    padding-left: 16px;

    .Input::placeholder {
      color: #d1d2d6;
    }

    &_small {
      line-height: 22px;

      &.withPostfix {
        padding: 9px 11px 9px 15px;
      }

      &:not(:disabled):not(.isInvalid) {
        &:hover,
        &:focus-within {
          // TODO
        }
      }
    }
  }
}

.isInvalid {
  border-color: rgba(255, 79, 60, 1);
}

.Input {
  width: 100%;
  padding: 0;
  appearance: none;
  background-color: inherit;
  color: inherit;
  line-height: inherit;
  border: none;
  outline: none;

  // https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  &:read-only {
    cursor: default;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Container {
    &__landing {
      padding: 16px;
      line-height: 17px;
    }

    &__cabinet {
      padding: 9px 15px;
    }
  }
}
