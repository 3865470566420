.DeleteReportButton {
  cursor: pointer;
  border: 0;
  background-color: unset;
  position: absolute;
  right: 16px;
  top: 20px;
  color: rgba(180, 39, 39, 0.6);
  transition: 0.125s color;

  &:hover {
    color: rgba(180, 39, 39, 0.9);
  }
}
