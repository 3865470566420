@use '../../../../app/styles/fonts';

.Task {
  position: relative;
  padding: 24px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.Header {
  align-items: center;
  justify-content: space-between;

  .Main {
    gap: 20px;

    .Photo {
      width: 88px;
      height: 88px;
      border-radius: 8px;
      object-fit: cover;
    }

    .Title {
      color: rgba(5, 8, 22, 1);
      @include fonts.inter_18_medium;
    }
  }
}

.Footer {
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid rgba(209, 210, 214, 1);

  .Stage {
    color: rgba(116, 119, 133, 1);
    @include fonts.inter_16_regular;
  }

  .CurrentStage {
    color: rgba(5, 8, 22, 1);
    @include fonts.inter_18_medium;
  }
}

.Id {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fonts.inter_12_medium;
  min-width: 32px;
  position: absolute;
  left: -12px;
  top: -8px;
  border-radius: 16px;
  background-color: rgb(226, 226, 255);
  opacity: 0.9;
  padding: 6px 8px;
}

.BonusPrice {
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 8px;
  border-radius: 12px;
  background-color: #e2e2ff;

  @include fonts.inter_12_regular;

  strong {
    @include fonts.inter_14_medium;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Task {
    padding: 16px;
  }

  .Header {
    flex-direction: column;
    align-items: start;
    gap: 24px;

    .Main {
      gap: 16px;

      .Photo {
        width: 64px;
        height: 64px;
      }

      .Title {
        @include fonts.inter_16_medium;
      }
    }
  }

  .Footer {
    margin-top: 20px;
    padding-top: 24px;

    .Stage {
      @include fonts.inter_14_regular;
    }
  }
}
