@use '../../../../app/styles/fonts';

.Label {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.Text {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_16_regular;
}

.Link {
  color: rgba(14, 14, 242, 1);
  @include fonts.inter_16_regular;
}

.TgConnect {
  padding: 32px;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  margin-top: 20px;
  gap: 24px;
}

.CheckButton {
  border: 1px solid var(--blue);
  color: var(--blue);
  padding: 0 14px;
  @include fonts.inter_16_regular;

  width: auto;

  &:hover {
    border: 1px solid var(--black);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .TgConnect {
    padding: 24px 16px;
    gap: 24px;
  }

  .Label {
    @include fonts.inter_16_medium;
  }

  .Text {
    @include fonts.inter_14_regular;
  }

  .Link {
    @include fonts.inter_14_regular;
  }
}
