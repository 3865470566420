@use '../../../../app/styles/fonts';

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_24_medium;
}

.Tabs {
  margin: 24px 0;
}

.Users {
  margin-top: 24px;
}

.Loader {
  margin-top: 20px;
  align-self: center;
}
