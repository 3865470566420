
.Block {
  padding: 24px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Block {
    padding: 16px;
  }
}
