@use '../../../app/styles/fonts';
@use '../../../app/styles/components' as *;

.MultiSelectFormField {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.SelectFormField {
  flex: 1;
}

.CloseIcon {
  cursor: pointer;
  color: var(--grey);
}

.AddButton {
  @extend %button;
  height: 40px;
  padding: 0 4px;
  color: rgba(116, 119, 133, 1);
  align-self: flex-start;
  @include fonts.inter_14_medium;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .MultiSelectFormField {
    gap: 4px;
  }
}
