@use "../../../app/styles/fonts";

.Uploader {
  width: 180px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  border: 1.5px dashed rgba(204, 206, 216, 1);
  border-radius: 12px;
  overflow: hidden;
  transition: 0.2s;

  &.isActive:not(.isDisabled),
  &:hover:not(.isDisabled) {
    background-color: rgba(245, 245, 245, 1);
    border-color: rgba(0, 10, 59, 1);
    cursor: pointer;
  }
}

.HiddenInput {
  display: none;
}

.Placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50px;
  @include fonts.inter_10_regular;

  path {
    fill: rgba(0, 10, 59, 1)
  }
}

.ImagePreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
