.Media {
  width: auto;
  flex: 1;
  min-width: 150px;
  max-width: 350px;

  & > div {
    aspect-ratio: 1 / 1.5;
    height: auto;
    max-height: 320px;
  }
}

.MediaUploadItem {
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 10px;
  width: auto;
  flex: 1;
  min-width: 150px;
  max-width: 350px;

  & > div {
    width: auto;
    height: auto;
    aspect-ratio: 1 / 1.5;
    max-height: 320px;
  }
}
