@use '../../../../app/styles/fonts';
@use '../../../../app/styles/components' as *;

.Layout {
  gap: 24px;
}

.EducationButton {
  @extend %button;
  text-decoration: none;
  height: 48px;
  border-radius: 10px;
  color: rgba(255, 255, 255, 1);
  background: #0e0ef2;
  padding: 0 16px;
  @include fonts.inter_16_medium;

  &:hover {
    background: #3939f5;
  }
}

.Loader {
  margin-top: 20px;
  align-self: center;
}

.EmptyState {
  margin: 0 auto;
  margin-top: 60px;
  align-self: center;
  max-width: 400px;
  text-align: center;

  h2 {
    @include fonts.inter_24_medium;
  }
}

.AddButton {
  color: var(--black);
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .EmptyState {
    h2 {
      @include fonts.inter_20_medium;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .EducationButton {
    width: 100%;
  }
}
