.Balance {
  width: 544px;
}

.InputsContainer {
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

.CabinetButton {
  align-self: center;
}
