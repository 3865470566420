@use '../../../../app/styles/fonts';

.Collections {
  flex: 1;
  gap: 20px;
  @include fonts.inter_14_regular;

  &__title {
    @include fonts.inter_24_medium;
  }
}

.CollectionType {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--white);
  border-radius: 16px;
  padding: 20px;

  &__title {
    @include fonts.inter_20_medium;
  }

  &__text {
    color: var(--grey);
    @include fonts.inter_14_medium;
  }

  &__price {
    @include fonts.inter_16_medium;

    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      color: var(--blue);
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Collections {
    gap: 16px;
  }

  .CollectionType {
  }
}
