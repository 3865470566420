@use '../../../../app/styles/fonts';

.Notification {
  padding: 20px 24px;
  border-radius: 16px;
  display: flex;
  gap: 32px;

  &System {
    background-color: #e2e2ff;
  }

  &Base {
    background-color: var(--white);
  }

  &Img {
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    min-width: 56px;
    max-width: 56px;
    height: 56px;
    border-radius: 100px;

    img {
      width: 56px;
      height: 56px;
    }
  }

  &Wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  &Main {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Text {
      @include fonts.inter_16_medium;
      color: var(--black);
      white-space: pre-wrap;
      word-break: break-word;
    }

    &Type {
      @include fonts.inter_14_regular;
      color: var(--grey);
    }
  }

  &Time {
    text-align: end;
    @include fonts.inter_16_regular;
    color: var(--grey);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Notification {
    padding: 16px;
    gap: 16px;

    &Wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &Main {
      gap: 4px;

      &Text {
        @include fonts.inter_14_medium;
      }

      &Type {
        @include fonts.inter_13_regular;
      }
    }

    &Img {
      min-width: 40px;
      max-width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &Time {
      @include fonts.inter_14_regular;
    }
  }
}
