@use '../../../../app/styles/fonts';
@use '../../../../app/styles/components' as *;

.Products {
  padding: 0 56px;

  &List {
    display: flex;
    overflow: auto;
    gap: 12px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.Item {
  padding: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;

  &Image {
    display: block;
    object-fit: cover;
    width: 296px;
    height: 310px;
    border-radius: 32px;
  }

  &Title {
    margin-top: 24px;
    @include fonts.hauss_24_medium;
  }

  &Price {
    margin-top: 12px;
    @include fonts.hauss_20_regular;
  }

  &Button {
    margin-top: 24px;
    @extend %button;
    padding: 14px 0;
    border-radius: 32px;
    color: var(--white);
    background-color: rgba(179, 29, 252, 1);
    @include fonts.hauss_18_medium;
    line-height: 27.68px;

    &:hover,
    &:active {
      color: var(--white);
      background-color: rgba(188, 51, 255, 1);
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Products {
    padding: 0 20px;
  }

  .Item {
    border-radius: 24px;

    &Image {
      width: 224px;
      height: 280px;
      border-radius: 16px;
    }

    &Title {
      margin-top: 20px;
      @include fonts.hauss_20_medium;
    }

    &Price {
      margin-top: 8px;
      @include fonts.hauss_16_regular;
    }

    &Button {
      margin-top: 20px;
      padding: 12px 0;
      @include fonts.hauss_16_medium;
    }
  }
}
