:root {
  --font-family-als-hauss-regular: 'ALS Hauss Regular', sans-serif;
  --font-family-als-hauss-medium: 'ALS Hauss Medium', sans-serif;
  --font-family-als-hauss-bold: 'ALS Hauss Bold', sans-serif;

  --font-family-inter-regular: 'Inter Regular', sans-serif;
  --font-family-inter-medium: 'Inter Medium', sans-serif;

  --black: #050816;
  --white: #ffffff;
  --white02: rgba(255, 255, 255, 0.2);
  --grey: #747785;
  --blue: #0e0ef2;
  --red: #ff3d28;
}
