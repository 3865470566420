@use "../../../../app/styles/fonts";

.CollectionSelectFormField {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.CollectionDescription {
  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  white-space: pre-line;
  @include fonts.inter_14_medium;

  .List {
    padding-left: 16px;
  }
}
