@use '../../../../app/styles/fonts';

.Layout {
  gap: 24px;
}

.Tabs {
  margin-top: 8px;
}
.Loader {
  margin-top: 20px;
  align-self: center;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Tasks {
    margin-top: 0;
  }

  .AddButton {
    margin-top: 24px;
    height: 48px;
    padding: 0 16px;

    span {
      display: block;
    }
  }
}
