@use '../../../../app/styles/fonts';

.Task {
  position: relative;
  padding: 24px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.Header {
  align-items: center;
  justify-content: space-between;

  .Main {
    gap: 20px;

    .Photo {
      width: 88px;
      height: 88px;
      border-radius: 8px;
      object-fit: cover;
    }

    .Title {
      color: rgba(5, 8, 22, 1);
      @include fonts.inter_18_medium;
    }

    .Text {
      @include fonts.inter_16_regular;
    }
  }
}

.Id {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fonts.inter_12_medium;
  min-width: 32px;
  position: absolute;
  left: -12px;
  top: -8px;
  border-radius: 16px;
  background-color: rgb(226, 226, 255);
  opacity: 0.9;
  padding: 6px 8px;
}

.Loader {
  margin-top: 20px;
  align-self: center;
}
