@use '../../../../app/styles/fonts';

.Block {
  gap: 24px;
}

.CabinetButton {
  margin-top: 32px;
  align-self: center;
}

.SendButton {
  border: 1px solid var(--blue);
  color: var(--blue);
  padding: 0 14px;
  @include fonts.inter_16_regular;

  width: auto;

  &:hover {
    border: 1px solid var(--black);
  }
}

.RealsInput {
  flex-grow: 1;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Block {
    gap: 20px;
  }

  .CabinetButton {
    margin-top: 24px;
    align-self: unset;
  }
}
