@use '../../../../app/styles/fonts.scss';

.Requisite {
  color: var(--white);
  @include fonts.hauss_20_regular;
  text-align: center;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 56px 80px 40px;
  border-radius: 72px;
  gap: 12px;
}

.Title {
  color: var(--white);
  text-align: center;
  @include fonts.hauss_56_medium;
}

.Link {
  color: var(--white);
}
