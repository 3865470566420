@use "../../../app/styles/fonts";

.Field {
  display: flex;
  flex-direction: column;

  &__landing {
    .Label {
      margin-bottom: 12px;
      color: rgba(15, 20, 20, 1);
      @include fonts.hauss_18_bold;
    }

    .Star {
      margin-left: 4px;
      color: rgba(3, 119, 255, 1);
    }

    .Error {
      margin-top: 12px;
      color: rgba(255, 79, 60, 1);
      @include fonts.hauss_12_medium;
    }
  }

  &__cabinet {
    .Label {
      margin-bottom: 8px;
      color: rgba(23, 28, 52, 1);
      @include fonts.inter_18_medium;
    }

    .Description {
      margin-top: 8px;
      color: rgba(116, 119, 133, 1);
      @include fonts.inter_13_regular;
    }

    .Error {
      margin-top: 8px;
      color: rgba(255, 79, 60, 1);
      @include fonts.inter_13_regular;
    }
  }

  &__smallCabinet {
    .Label {
      margin-bottom: 12px;
      color: rgba(23, 28, 52, 1);
      @include fonts.inter_14_medium;
    }

    .Description {
      margin-top: 8px;
      color: rgba(116, 119, 133, 1);
      @include fonts.inter_13_regular;
    }

    .Error {
      margin-top: 8px;
      color: rgba(255, 79, 60, 1);
      @include fonts.inter_13_regular;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Field {
    &__landing {
      .Label {
        margin-bottom: 12px;
        @include fonts.hauss_16_bold;
      }

      .Error {
        margin-top: 8px;
      }
    }

    &__cabinet {
      .Label {
        margin-bottom: 6px;
        @include fonts.inter_16_medium;
      }
    }

    &__smallCabinet {
      .Label {
        margin-bottom: 8px;
      }
    }
  }
}
