.Video {
  display: flex;
  justify-content: center;
}

.Iframe {
  position: absolute;
  z-index: 2;
  width: 928px;
  height: 522px;
  border-radius: 30px;
}

.PreviewImage {
  cursor: pointer;
  position: relative;

  width: 928px;
  height: 522px;

  overflow: hidden;
  border-radius: 30px;
  transition: 0.125s scale;

  img {
    width: 100%;
  }

  @media (hover: hover) and (min-width: 768px) {
    &:hover {
      scale: 1.01;
    }
  }
}

.Btn {
  overflow: hidden;
  cursor: pointer;
  position: absolute;

  width: 90px;
  height: 63px;

  background: url('../../../../shared/assets/youtube_btn.svg');
  background-size: cover;

  border: none;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Active > .PreviewImage,
.Active > .Btn {
  opacity: 0;
  pointer-events: none;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Iframe {
    width: 360px;
    height: 202px;
  }

  .PreviewImage {
    width: 360px;
    height: 202px;
  }

  .Btn {
    opacity: 1;
    width: 60px;
    height: 42px;
  }
}
