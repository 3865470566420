.HeaderImages {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.BackgroundImages {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  right: 0;
}

.TopImageCard {
  z-index: 1;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .HeaderImages {
    max-width: 320px;
  }
}
