@use '../../../../app/styles/fonts';

.Blocks {
  gap: 24px;
  flex-wrap: wrap;
  flex-direction: column;
}

.Block {
  width: 100%;
  min-width: 320px;
  flex: 1;
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  height: fit-content;
  gap: 16px;

  &BankDetails {
    gap: 0;
  }
}

.Avatar {
  margin-bottom: 24px;
}

.Title {
  color: rgb(23, 28, 52);
  @include fonts.inter_22_medium;
}

.Description {
  @include fonts.inter_13_regular;
  color: rgb(116, 119, 133);
}

.BlogInfo__title {
  margin-bottom: 16px;
}

.BankDetails {
  width: 100%;

  &Verif {
    margin-top: 16px;
  }
}

.EditButton {
  @include fonts.inter_16_regular;
  max-width: max-content;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Title {
    color: rgb(23, 28, 52);
    @include fonts.inter_20_medium;
  }
}
