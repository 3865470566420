.Background {
  img {
    z-index: -1;
    position: absolute;
  }

  .MainBackgroundGradient1 {
    top: 0px;
    left: -200px;
    transform: rotate(90deg);
  }

  .MainBackgroundGradient3 {
    top: 120px;
    right: 30px;
  }

  .TheyTalkAboutUsBackgroundGradient {
    bottom: 645px;
    left: 165px;
  }

  .BottomBackgroundGradient {
    display: none;
    bottom: 0px;
    left: -300px;
  }
}

.Products {
  margin-top: 164px;
}

.Stages {
  margin-top: 164px;
}

.BloggersCount {
  margin-top: 240px;
}

.TheyTalkAboutUs {
  margin-top: 240px;
}

.AnyQuestions {
  margin-top: 318px;
  margin-bottom: 220px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Products {
    margin-top: 120px;
  }

  .Stages {
    margin-top: 120px;
  }

  .BloggersCount {
    margin-top: 120px;
  }

  .TheyTalkAboutUs {
    margin-top: 120px;
  }

  .AnyQuestions {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .Background {
    .MainBackgroundGradient1 {
      width: 450px;
      top: 0px;
      left: -130px;
      transform: rotate(0deg);
    }

    .MainBackgroundGradient3 {
      width: 500px;
      top: 200px;
      right: -40px;
    }

    .TheyTalkAboutUsBackgroundGradient {
      display: none;
    }

    .BottomBackgroundGradient {
      display: block;
    }
  }
}
