.Container {
  display: flex;
  flex-direction: column;
}

.Input {
  padding-right: 8px;
}

.Icon {
  padding: 8px;
  height: 40px;
  background-color: #f5f5f7;
  border-radius: 8px;
}
