@use '../../../../app/styles/fonts';

.Blogger {
  margin-top: 24px;
  background-color: rgba(255, 255, 255, 1);
  padding: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;

  .Avatar {
    min-width: 56px;
    height: 56px;
  }

  .FirstName {
    @include fonts.inter_16_medium;
  }

  .Item {
    min-width: 150px;

    @include fonts.inter_14_regular;
  }

  .Info {
    gap: 24px;

    .Item {
      min-width: unset;
      flex-direction: column;
      gap: 4px;
      align-items: end;
    }

    .Title {
      white-space: nowrap;
      @include fonts.inter_14_regular;
    }

    .Value {
      @include fonts.inter_14_medium;
    }

    .Tags {
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .Tag {
      white-space: nowrap;
      height: 24px;
      border-radius: 12px;
      padding: 0 8px;
      background-color: rgba(241, 241, 241, 1);
      @include fonts.inter_12_medium;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Blogger {
    margin-top: 20px;
    padding: 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .Info {
      flex-direction: column;
      gap: 16px;

      .Item {
        gap: 8px;
        align-items: flex-start;
      }
    }
  }
}
