@use '../../../../app/styles/fonts';

.CabinetPageLayout {
  box-sizing: content-box;
  max-width: 1104px;
  margin: 0 auto;
}

.Main {
  gap: 12px;

  .Photo {
    object-fit: cover;
    border-radius: 8px;
    width: 56px;
    height: 56px;
  }

  .Title {
    color: rgba(5, 8, 22, 1);
    @include fonts.inter_18_medium;
  }
}

.Body {
  margin-top: 32px;
  gap: 16px;

  .Block {
    gap: 24px;
  }
}
.marketplaceName {
  @include fonts.inter_16_medium;
}

.RejectButton {
  align-self: flex-end;
  max-width: 200px;
}

.Completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  margin: 80px auto 0;

  &__title {
    width: 120px;
  }

  &__text {
    @include fonts.inter_32_medium;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Main {
    gap: 16px;

    .Photo {
      width: 64px;
      height: 64px;
    }

    .Title {
      @include fonts.inter_16_medium;
    }
  }

  .Body {
    margin-top: 24px;
    gap: 24px;
    flex-direction: column;

    .Block {
      gap: 16px;
    }
  }

  .Completed {
    &__title {
      width: 100px;
      height: 100px;
    }

    &__text {
      @include fonts.inter_24_medium;
    }
  }
}
