@use '../../../../app/styles/components' as *;
@use '../../../../app/styles/fonts';

.Header {
  z-index: 3;
  top: 0;
  position: sticky;
  height: 80px;
  background-color: rgba(255, 255, 255, 1);
  padding: 0 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(209, 210, 214, 1);
}

.Navigation {
  margin-left: 48px;
  display: flex;

  &Item {
    padding: 8px 14px;
    color: rgba(116, 119, 133, 1);
    text-decoration: none;
    border-radius: 8px;
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 1);
    @include fonts.inter_16_regular;

    &_active {
      color: var(--black);
    }

    &:hover,
    &:active {
      color: rgba(23, 28, 52, 1);
      background-color: rgba(245, 245, 247, 1);
    }
  }
}

.Menu {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 24px;
}

.Avatar {
  margin-left: 24px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.MenuIcon {
  display: none;
  margin-left: 16px;
  cursor: pointer;

  path {
    fill: rgba(23, 28, 52, 1);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Header {
    height: 68px;
    padding: 0 20px;
  }

  .Navigation {
    display: none;
  }

  .Menu {
    display: none;
  }

  .Avatar {
    height: 36px;
    width: 36px;
    margin-left: auto;
  }

  .MenuIcon {
    display: block;
  }
}
