@use '../../../app/styles/fonts';

.Avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 112px;
  background-color: rgba(116, 119, 133, 1);
  overflow: hidden;

  &_square {
    border-radius: 16px;
  }
}

.Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Chart {
  color: rgba(255, 255, 255, 1);
  @include fonts.inter_16_regular;
}
