.CabinetPageLayout {
  display: flex;
  gap: 32px;

  width: 100%;
  max-width: 896px;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 32px;
  gap: 32px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .CabinetPageLayout {
    padding: 0 20px;
    flex-direction: column-reverse;
    margin-top: 24px;
  }
}
