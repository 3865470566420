@use '../../../../app/styles/fonts';

.Stages {
  padding: 0 56px;
  color: rgba(255, 255, 255, 1);
}

.Title {
  @include fonts.hauss_64_medium;
}

.List {
  margin-top: 80px;
  display: flex;
  gap: 80px 28px;
  flex-wrap: wrap;
}

.Item {
  list-style: none;
  flex: 1 30%;

  &Number {
    padding: 16px 24px;
    border-radius: 12px;
    @include fonts.hauss_32_medium;
  }

  &Text {
    margin-top: 24px;
    @include fonts.hauss_32_regular;
  }

  &:nth-child(1) {
    .ItemNumber {
      background-color: rgba(86, 50, 255, 1);
    }
  }

  &:nth-child(2) {
    .ItemNumber {
      background-color: rgba(246, 186, 69, 1);
    }
  }

  &:nth-child(3) {
    .ItemNumber {
      background-color: rgba(179, 29, 252, 1);
    }
  }

  &:nth-child(4) {
    .ItemNumber {
      background-color: rgba(228, 94, 54, 1);
    }
  }

  &:nth-child(5) {
    .ItemNumber {
      background-color: rgba(215, 54, 155, 1);
    }
  }

  &:nth-child(6) {
    .ItemNumber {
      background-color: rgba(26, 234, 122, 1);
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Stages {
    padding: 0 20px;
  }

  .Title {
    @include fonts.hauss_32_medium;
  }

  .List {
    margin-top: 56px;
    flex-direction: column;
    gap: 32px;
  }

  .Item {
    &Number {
      padding: 10px 16px;
      border-radius: 8px;
      @include fonts.hauss_24_medium;
    }

    &Text {
      margin-top: 20px;
      @include fonts.hauss_24_regular;
    }
  }
}
