@use '../../../app/styles/fonts';

.Accordion {
  display: flex;
  flex-direction: column;

  &__landing {
    gap: 24px;
  }

  &__cabinet {
    gap: 16px;
  }
}

.AccordionItem {
  padding: 28px 24px;
  text-align: left;
  border-radius: 24px;
  cursor: pointer;

  transition: 0.125s background-color;

  &__landing {
    color: rgba(255, 255, 255, 1);

    background-color: rgba(5, 8, 22, 1);
    border: 1px solid rgba(255, 255, 255, 0.4);

    &:hover {
      background-color: rgba(19, 20, 22, 1);
    }
  }

  &__landing &Title {
    @include fonts.hauss_18_medium;
  }

  &__cabinet {
    color: var(--black);
    border: 1px solid #ffffff66;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 20px 0px #0000000d;

    &:hover {
      background-color: #f5f5f7;
    }
  }

  &__cabinet &Title {
    @include fonts.inter_18_medium;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }

  &Icon {
    flex-shrink: 0;
    transition: transform 0.3s;

    &__isOpen {
      transform: rotate(180deg);
    }
  }

  &AnswerContainer {
    // https://dev.to/francescovetere/css-trick-transition-from-height-0-to-auto-21de
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s;

    &__isOpen {
      grid-template-rows: 1fr;
    }

    &__overflow {
      overflow: hidden;
    }
  }

  &__landing &AnswerContainer {
    @include fonts.hauss_16_regular;
  }

  &__cabinet &AnswerContainer {
    @include fonts.inter_16_regular;
  }

  &Answer {
    padding-top: 28px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Accordion {
    &__landing {
      gap: 20px;
    }

    &__cabinet {
      gap: 16px;
    }
  }

  .AccordionItem {
    padding: 20px 24px;
    border-radius: 20px;
  }
}
