@use '../../../../app/styles/fonts';

.CommentsAccordion {
  margin-top: 16px;

  & > div {
    border-radius: 16px;
    box-shadow: none;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.Comment {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  white-space: pre-line;
  @include fonts.inter_16_regular;

  &Title {
    @include fonts.inter_18_medium;
  }
}

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}
