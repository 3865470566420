@use "../../../../app/styles/fonts.scss";

.Description {
  @include fonts.hauss_24_regular;

  .DescriptionTitle {
    color: rgba(255, 255, 255, 1);
  }

  .DescriptionText {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Description {
    @include fonts.hauss_18_regular;

    .DescriptionTitle {
      display: none;
    }

    .DescriptionText {
      color: rgba(255, 255, 255, 1);
    }
  }
}
