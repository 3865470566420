@use "../../../../app/styles/fonts";

.CabinetPageLayout {
  width: 100%;
  max-width: 544px;
  box-sizing: content-box;
  margin: 0 auto;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
