@use '../../../../app/styles/fonts';

.Tariff {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 48px 40px 32px;
  border-radius: 48px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  min-width: 0px;
}

.Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include fonts.hauss_48_bold;
}

.Integrations {
  @include fonts.hauss_20_medium;
  margin-top: 8px;
}

.List {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: rgba(135, 135, 135, 1);
}

.Checkbox {
  color: #878787;
  flex-shrink: 0;
}

.Item {
  @include fonts.hauss_20_regular;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Price {
  margin-top: 56px;
  @include fonts.hauss_48_bold;
}

.SubPrice {
  @include fonts.hauss_20_regular;
  color: rgba(135, 135, 135, 1);
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Tariff {
    @include fonts.hauss_18_medium;
  }

  .Title,
  .Tariff__isPremium .Title {
    @include fonts.hauss_40_bold;
  }

  .Integrations,
  .Tariff__isPremium .Integrations {
    margin-top: 4px;
  }

  .List,
  .Tariff__isPremium .List {
    margin-top: 24px;
    @include fonts.hauss_18_regular;
  }

  .Item,
  .Tariff__isPremium .Item {
    gap: 14px;
  }

  .Price,
  .Tariff__isPremium .Price {
    margin-top: 40px;
    @include fonts.hauss_40_bold;
  }

  .BigStarContainer {
    right: 20px;
    bottom: 33px;
    align-items: end;

    svg {
      width: 76px;
      height: 76px;
    }
  }
}
