@use '../../../../app/styles/fonts';

.CabinetLayout {
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
}

.Container {
  flex: 1;
  overflow: auto;
  z-index: 2;
}

.Toaster {
  @include fonts.inter_16_regular;
}

.ToasterContainer {
  top: 90px !important;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .ToasterContainer {
    top: 75px !important;
  }
}
