@use '../../../../app/styles/fonts';

.Title {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_32_medium;
}

.Description {
  @include fonts.inter_14_regular;
  color: var(--grey);
}

.Layout {
  gap: 32px;
}

.Search {
  max-width: 450px;
  width: 100%;
}

.Text {
  margin-top: 12px;
  max-width: 800px;
  @include fonts.inter_16_regular;
}

.Blogger {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  white-space: pre-line;
  @include fonts.inter_16_regular;
  width: max-content;
}

.ResultTitle {
  margin-bottom: 8px;
  @include fonts.inter_18_medium;
}
