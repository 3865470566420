@use '../../../../app/styles/fonts.scss';

.TelegramButton {
  border-radius: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 256px;
  height: 256px;
  cursor: pointer;

  &:hover {
    .Circle {
      width: 272px;
      height: 272px;
    }
  }
}

.Circle {
  position: absolute;
  width: 256px;
  height: 256px;
  border-radius: 136px;
  background: linear-gradient(0deg, rgba(16, 99, 227, 1) 0%, rgba(48, 132, 230, 1) 100%);
  transition: 0.3s;
}

.Icon {
  fill: white;
  z-index: 1;
  width: 64px;
  height: 64px;
}

.Text {
  z-index: 1;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  @include fonts.hauss_24_medium;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .TelegramButton {
    gap: 8px;
    width: 164px;
    height: 164px;

    &:hover {
      .Circle {
        width: 172px;
        height: 172px;
      }
    }
  }

  .Circle {
    width: 164px;
    height: 164px;
  }

  .Icon {
    width: 32px;
    height: 32px;
  }

  .Text {
    @include fonts.hauss_18_medium;
    line-height: 23.9px;
  }
}
