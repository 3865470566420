@use '../../../../app/styles/fonts';

.WaitPaymentStage {
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 32px;
}

.Title {
  margin-top: 32px;
  margin-right: 60px;
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.BankDetails {
  margin-top: 24px;
}

.Label {
  color: rgba(116, 119, 133, 1);
  @include fonts.inter_16_regular;
}

.Text {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_16_medium;
}

.BonusPrice {
  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
}

.UploaderField {
  margin-top: 32px;
  margin-bottom: 32px;
}

.CabinetButton {
  margin-top: 32px;
  align-self: center;
}

.ProblemButton {
  height: 45px;
  margin-top: 24px;
  align-self: center;
  background-color: transparent;
  color: var(--red);
  border: 1px solid var(--red);
  opacity: 0.8;

  &:hover {
    opacity: 1;
    background-color: transparent;
  }
}

.Comment {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
  white-space: pre-line;
  @include fonts.inter_16_regular;

  &Wrap {
    padding: 32px;
    border-radius: 12px;
    margin-top: 16px;
    background-color: var(--white);
  }

  &Title {
    @include fonts.inter_18_medium;
  }

  &Warning {
    color: var(--red);
    @include fonts.inter_16_regular;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .WaitPaymentStage {
    padding: 24px 16px;
  }

  .CabinetButton {
    margin-top: 24px;
    align-self: stretch;
  }
}
