@use '../../../../app/styles/fonts';

.ProgressStage {
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 32px;
}

.Title {
  margin-top: 32px;
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_18_medium;
}

.RemainingTime {
  span {
    white-space: pre-wrap;
  }
}

.Label {
  @include fonts.inter_16_regular;
  color: var(--blue);
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .ProgressStage {
    padding: 24px 16px;
  }
}
