@use "../../../../app/styles/fonts";

.Block {
  gap: 24px;
}

.CabinetButton {
  margin-top: 32px;
  align-self: center;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Block {
    gap: 20px;
  }

  .CabinetButton {
    margin-top: 24px;
    align-self: unset;
  }
}
