@use '../../../../app/styles/fonts';

.Tariffs {
  flex: 1;
  gap: 20px;
  @include fonts.inter_14_regular;

  &__title {
    @include fonts.inter_24_medium;
  }
}

.TariffsList {
  padding: 0 20px;
  background-color: var(--white);
  border-radius: 16px;
}

.TariffsItem {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin: 20px 0;

  &__Title {
    @include fonts.inter_20_medium;
  }

  &__Integrations {
    font-weight: 500;
    color: var(--grey);
  }

  &__Price {
    font-size: 20px;
    font-weight: 600;
  }
}

.Separator {
  border: 0;
  border-bottom: 1px solid #0000001a;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Tariffs {
    max-width: none;
    gap: 16px;

    &__title {
      @include fonts.inter_24_medium;
    }
  }
}
