@use "../../../../app/styles/fonts";

.Form {
  display: flex;
  flex-direction: column;
}

.CustomerName {
  color: rgba(5, 8, 22, 1);
  @include fonts.inter_16_medium;
}

.Buttons {
  margin-top: 32px;
  width: 288px;
  align-self: center;
}
