@use '../../../app/styles/fonts';

.Overlay {
  position: fixed;
  inset: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px;
}

.Container {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 32px;
  max-height: 100%;
  overflow: auto;

  &__small {
    padding: 20px;

    .Title {
      @include fonts.inter_18_medium;
    }
  }
}

.Title {
  @include fonts.inter_24_medium;
}

.CloseIcon {
  cursor: pointer;
  color: var(--grey);
}

.isOpen {
  max-height: 100%;
  overflow: hidden;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Container {
    border-radius: 8px;
    padding: 16px;
  }

  .Title {
    @include fonts.inter_18_medium;
  }
}
