@use '../../../app/styles/fonts';

.Product {
  position: relative;
  width: 320px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.Photo {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.Body {
  padding: 16px;
}

.Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include fonts.inter_16_medium;
}

.Price {
  @include fonts.inter_16_medium;
}

.Status {
  width: 24px;
  height: 24px;
}

.StatusWrap {
  height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--white);
  padding: 10px;
  border-radius: 0 16px 0 16px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
}

.Id {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fonts.inter_12_medium;
  min-width: 32px;
  position: absolute;
  left: -12px;
  top: -8px;
  border-radius: 16px;
  background-color: rgb(226, 226, 255);
  padding: 6px 8px;
}
