@use '../../../../app/styles/fonts';

.Benefits {
  flex: 1;
  gap: 20px;
  @include fonts.inter_14_regular;

  &__title {
    @include fonts.inter_24_medium;
  }
}

.BenefitsList {
  border-radius: 16px;
  padding: 20px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 12px;
}

.BenefitsItem {
  display: flex;
  align-items: center;
  gap: 8px;
  @include fonts.inter_14_medium;

  &__Checkbox {
    width: 24px;
    height: 24px;
    color: var(--black);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Benefits {
    gap: 16px;
  }
}
