@use "../../../../app/styles/fonts";

.ImageCard {
  display: inline-flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 20px;
  background-size: cover;

  &__small {
    width: 200px;
    height: 232px;
    border-radius: 32px;
  }

  &__big {
    width: 240px;
    height: 280px;
    border-radius: 40px;
  }
}

.Rating {
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  padding: 6px 8px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  gap: 4px;

  @include fonts.hauss_14_medium;
  line-height: 19px;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .ImageCard {
    padding-bottom: 8px;
    border-radius: 20px;

    &__small {
      width: 100px;
      height: 124px;
    }

    &__big {
      width: 132px;
      height: 156px;
    }
  }

  .Rating {
    @include fonts.hauss_12_medium;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
