@use '../../../../app/styles/fonts';

.Block {
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.marketplaceName {
  @include fonts.inter_18_medium;
}

.Button {
  margin-top: 24px;
}

.BonusPrice {
  background-color: rgba(226, 226, 255, 1);
  padding: 16px;
  border-radius: 8px;
}

.Label {
  @include fonts.inter_18_medium;
}

.Text {
  @include fonts.inter_16_medium;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Block {
    gap: 16px;
  }
}
