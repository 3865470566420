@use '../../../app/styles/fonts';
@use '../../../app/styles/components' as *;

.CabinetActionButton {
  @extend %button;
  height: 40px;
  border-radius: 10px;
  width: 100%;
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(0deg, #0e0ef2 0%, rgba(14, 14, 242, 1) 100%);
  @include fonts.inter_14_medium;

  &:disabled {
    opacity: 0.6;
  }

  &:not(:disabled) {
    &:hover,
    &:active {
      background: linear-gradient(0deg, rgba(48, 132, 230, 1) 0%, rgba(16, 99, 227, 1) 100%);
    }
  }

  &__kindGhost {
    color: rgba(116, 119, 133, 1);
    background: transparent;

    &:not(:disabled) {
      &:hover,
      &:active {
        color: rgba(5, 8, 22, 1);
        background: transparent;
      }
    }
  }

  &__kindDanger {
    color: rgba(255, 255, 255, 1);
    background: rgba(255, 61, 40, 1);
    &:not(:disabled) {
      &:hover,
      &:active {
        background: rgba(255, 30, 5, 1);
      }
    }
  }
}
