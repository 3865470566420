@use '../../../../app/styles/fonts';

.TheyTalkAboutUs {
  padding: 0 56px;
  overflow: hidden;
}

.Title {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  @include fonts.hauss_64_medium;
}

.Comments {
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(3, minmax(120px, 1fr));
  gap: 16px;

  &Column {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.Comment {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 24px 16px;
  background: #e2e2ff;
  @include fonts.hauss_20_regular;

  border-radius: 20px;

  &Name {
    @include fonts.hauss_medium;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .TheyTalkAboutUs {
    padding: 0 20px;
  }

  .Title {
    @include fonts.hauss_32_medium;
  }

  .Comments {
    grid-template-columns: repeat(1, minmax(120px, 1fr));
    margin-top: 40px;

    &Column:nth-child(3) {
      display: none;
    }
  }
}
