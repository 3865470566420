@use "../../../../app/styles/fonts";

.Container {
  border-top: 1px solid rgba(209, 210, 214, 1);
  padding-top: 16px;
  text-align: center;
}

.Text {
  margin: 0 34px;
  color: rgba(14, 14, 242, 1);
  @include fonts.inter_12_regular;
}
